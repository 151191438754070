import { Action } from '@admin/interfaces/Action';
import { isIconOption } from '@common/atoms/Icon';

export class ActionsUtil {
    static format(_actions: any): Action[] {
        let actions: Action[] = [];
        if (typeof _actions === 'object') {
            Object.keys(_actions).forEach((key: any) => {
                const value: any = _actions[key];
                const action: Action = {
                    id: key,
                    title: key,
                    event: '',
                };

                if (!isNaN(key) && typeof value === 'string') {
                    action.id = value;
                    action.title = value;
                } else if (typeof value === 'object') {
                    if (value.title) {
                        action.title = value.title;
                    }

                    if (value.allowMulti !== undefined) {
                        action.allowMulti = value.allowMulti;
                    }

                    if (value.isHidden !== undefined) {
                        action.isHidden = value.isHidden;
                    }

                    if (value.event) {
                        action.event = value.event;
                    } else {
                        action.event = key;
                    }

                    if (value.href) {
                        action.href = value.href;
                    }

                    if (value.callback) {
                        action.callback = value.callback;
                    }

                    if (isIconOption(value.icon)) {
                        action.icon = value.icon;
                    }
                } else if (key === 'submit' || key === 'cancel') {
                    action.title = value;
                    action.event = key;
                }

                actions.push(action);
            });
        } else if (_actions instanceof Array) {
            actions = _actions.map((key) => {
                return {
                    id: key,
                    title: key,
                    event: key,
                };
            });
        }
        return actions;
    }
}
